import { HStack, Tr, Th, Td, Spinner, TableContainer, Table, Thead, Tbody, Spacer, Box, Stack, Link } from "@chakra-ui/react";
import { OriginalID, UIDSearchBar, MainHeader, MonthFilter, SubStatusFilter, MainFooter, Pagination } from "components"
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { notificationQuery, notificationCountQuery, notificationPageState } from "state"
import { timestampMsToDateStr } from "utils";
import { Link as RouterLink } from "react-router-dom";
import { ROUTE_URI_PROFILES } from "utils";

export function Notifications() {
    return (
        <Stack w="100%">
            <Stack>
                <MainHeader totalState={notificationCountQuery} />
                <HStack spacing='24px'>
                    <OriginalID />
                    <UIDSearchBar />
                    <SubStatusFilter />
                    <MonthFilter />
                </HStack >
            </Stack>
            <Box px={4}>
                <Suspense fallback={<Spinner />}>
                    <Stack>
                        <NotificationsTab />
                    </Stack>
                </Suspense>
            </Box>
            <Spacer />
            <MainFooter>
                <Pagination
                    state={notificationPageState}
                    totalState={notificationCountQuery}
                />
                <Spacer />
            </MainFooter>
        </Stack>
    )
}

function NotificationsTab() {
    const notifications = useRecoilValue(notificationQuery)
    return (
        <Stack>
            <TableContainer>
                <Table variant="simple" size="m">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Source</Th>
                            <Th>Product ID</Th>
                            <Th>Original ID</Th>
                            <Th>UID</Th>
                            <Th>Type</Th>
                            <Th>Message At</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {notifications.map((value: any) => (
                            <Tr _hover={{ bg: "red.100" }} height="60px" key={String(value.id)}>
                                <Td paddingRight="25px">{value.id}</Td>
                                <Td paddingRight="25px">{value.source === 1 ? 'App Store' : 'Google Play'}</Td>
                                <Td paddingRight="25px">{value.productId}</Td>
                                <Td paddingRight="25px">{value.originalTransactionId}</Td>
                                <Td paddingRight="25px" color="#427FF6">
                                    <Link
                                        as={RouterLink}
                                        to={`${ROUTE_URI_PROFILES}/${value.uid}`}>
                                        {value.uid}
                                    </Link>
                                </Td>
                                <Td paddingRight="25px">{value.typeDes}</Td>
                                <Td>{timestampMsToDateStr(value.messageAt)}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Stack>

    )

}