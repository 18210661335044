import { RoleStatus } from "types";
import { FilterSelector, SelectFilterOption } from "./filter-status.view";
import { violationsRoleState } from "./filter.state";

export const violationRoleStatuses = [
    RoleStatus.All,
    RoleStatus.User,
    RoleStatus.Visitor,
];

export function ViolationsRoleFilter() {
    return (
        <FilterSelector
            label="Status"
            statusState={violationsRoleState}
            options={violationRoleStatuses.map((status, index) => {
                console.log(RoleStatus[status]);
                
                const option: SelectFilterOption = {
                    label: RoleStatus[status],
                    value: index,
                };
                return option;
            })}
        />
    )
}