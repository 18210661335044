export enum Gender {
  Woman,
  Man,
}

export enum ProfileStatus {
  Deleted = -1,
  Pending,
  Approved,
  Banned,
  Underage,
  All = 99,
}
export enum BioStatus {
  Deleted,
  Pending,
  Rejected,
  Approved = 99,
}

export enum subStatus{
  All,
  AppStore = 1,
  GooglePlay = 2
}

export enum roleStatus{
  User,
  Reviewer,
  Bot,
  Visitor
}

export type changeProfileType = {
  birthday?: number | null;  // 允许为 null
  deviceStatus?: string;
  id: number | null;
  status?: string;
  gender?: number;          // 可选属性
};
