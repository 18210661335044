import { PhotosResp } from "types";

export enum ViolationStatus {
  Pending,
  Approved,
  Rejected,
}

export enum RoleStatus{
  All = -1,
  User = 0,
  Visitor = 3,
}

export type violationType = {
  channelType: number,
  id: string,
  nsfwMedia: PhotosResp,
  nsfwQuantity: number,
  nsfwScore: number,
  nsfwTotalScore: number
  uid: string
}
